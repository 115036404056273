import * as React from "react";
import { StaticImage} from "gatsby-plugin-image";
import { PageContent, Heading, Paragraph, Box } from "grommet";

import Page from "../components/page";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Les pensions | Les écuries du cerf pension pour cheval"
        description="Liste des transports disponibles aux écuries du cerf, écuries de propriétaires à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading textAlign="center" alignSelf="center">
          Transport de votre équidé
        </Heading>
        <Paragraph fill>
        Nous vous proposons un service de transport pour votre équidé.<br />
Un devis sera établie en fonction de votre demande. Vous pouvez nous contacter soit par SMS (0634719911 ou 0628030954) soit par mail sur <a href="mailto:contact@lesecuriesducerf.fr">contact@lesecuriesducerf.fr</a>
        </Paragraph>
        <Paragraph fill>
        Nous bénéficions d'un camion 2 places et d'un van 2 places pour pouvoir honorer au maximum vos demandes.
        </Paragraph>
        <Box direction="row" justify="center" align="center" gap="medium">
          <StaticImage style={{width: "40%"}} src="../images/logo_ecuries_victoria_goupillot.jpg" alt="Logo victoria goupillot" />
          <StaticImage style={{width: "38%"}}src="../images/logo.svg" alt="Logo les ecuries du cerf" />
        </Box>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
